/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css?family=Lato:400,900');
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/bootstrap-slider/dist/css/bootstrap-slider.min.css";

* {
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

:root {
  --color-rating-positive: #99a8b2;
  --color-rating-neutral: #d0d6d9;
  --color-rating-negative: #a2a2a2;

  --height-header: 80px;
}

body {
  font-family: Lato, sans-serif;
  color: #666666;
  background-color: #f3f3f3;
  font-size: 12px;
}

h1 {
  font-size: 14px;
  color: #b3c1ca;
}

h2 {
  font-size: 34px;
  margin-bottom: 30px;
}

h3 {
  font-size: 28px;
  margin-bottom: 40px;
}

h4 {
  font-size: 24px;
  margin-bottom: 30px;
}

h5 {
  font-size: 18px;
  margin-bottom: 0;
}

h1, h2, h3 {
  text-transform: uppercase;
}

h1, h2, h3, h4 {
  font-weight: 900;
}

h5 {
  font-weight: normal;
}

.cursor-pointer {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  width: 930px;
}

table.editMode {
  width: 1035px;
}

table.editMode th,
table.editMode td {
  border-right: 1px dashed darkgray;
}

table.editMode th:last-child,
table.editMode td:last-child {
  border-right: none;
}

input {
  border-radius: 5px;
  box-shadow: none;
  background-color: #b3c1ca;
  color: white;
  height: 30px;
  padding: 10px;
  border: none;
}

table.editMode input {
  letter-spacing: 0.5px;
}


.lsz-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  border: solid 2px #b3c1ca;
  background-color: #b3c1ca;
  color: white;
  height: 30px;
  font-size: 14px;

  &:disabled {
    opacity: 0.5;
  }
}

.lsz-input-group {
  input {
    border-radius: 5px 0 0 5px;
  }

  button {
    border-radius: 0 5px 5px 0;
  }
}

.input-container {
  height: 60px;
}

table.editMode textarea {
  border-radius: 5px;
  background-color: #cacaca;
  color: white;
  border: 1px solid #c2c2c2;
  height: 80px;
}

table.editMode select, .lsz-select {
  background-color: #b3c1ca;
  color: white;
  border-radius: 5px;
  height: 30px;
  padding: 0 5px;
  font-size: 14px;
  border: none;
  scrollbar-width: unset;
}


tbody:nth-child(odd) td {
  /* Applying to the TD element so it also works with frozen columns (position: absolute) */
  background-color: #e3e3e3;
}

th, td {
  width: 105px;
  height: 40px;
  text-align: center;
  white-space: nowrap;
  padding: 10px;
}

th {
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  background-color: rgba(102, 102, 102, 0.8);
}

td {
  vertical-align: top;
}

th:first-child, td:first-child {
  text-align: left;
  padding-left: 10px;
  width: 180px;
}

th:first-child,
td:first-child {
  font-weight: bold;
}

table:not(.personnel-table) thead th {
  position: sticky;
  top: 80px;
}

.frozen-column-table-scroller {
  width: auto;
  overflow-x: scroll;
  margin-right: 105px;
  margin-left: 180px;
  overflow-y: visible;
  padding-bottom: 1px;
}

.frozen-column-table-scroller table {
  width: auto;
}

.frozen-column-table-scroller th:first-child,
.frozen-column-table-scroller td:first-child {
  position: absolute;
  left: 45px;
  top: auto;
}

.frozen-column-options {
  position: absolute;
  width: 105px;
  right: 0;
  top: auto;
  padding: 5px;
}

.sticky-last-edit {
  position: absolute;
  width: 150px;
  right: -45px;
  top: auto;
  padding: 5px;

  &.editMode {
    right: -150px;
  }
}


.frozen-column-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.frozen-column-table-scroller th:first-child .frozen-column-header {
  left: 10px;
  transform: translateY(-50%);
}


header {
  border-bottom: 2px lightgray solid;
}

nav ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

header nav ul li {
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
  margin-left: 20px;
}

header nav ul li:first-child {
  margin-left: 0;
}

header nav ul li a {
  color: #666666;
}

header nav ul li a:hover {
  color: #666666;
  text-decoration: none;
  border-bottom: 3px solid #b3c1ca;
  padding-bottom: 4px;
  font-weight: 900;
}

.category h2, .category-header {
  text-transform: uppercase;
  background-color: #6e818e;
  text-align: center;
  color: white;
  font-size: 14px;
  height: 40px;
  vertical-align: middle;
  border-radius: initial;
  font-weight: 900;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: none;
}

.category-header.collapsed {
  background-color: #b3c1ca;
}

.category-header:hover {
  color: white;
  text-decoration: none;
}

.accordion .card-header {
  padding: 0;
}

.count {
  font-size: 16px;
  font-weight: 900;
  position: relative;
  padding: 30px 0 14px 0;
}

.baseline {
  border-bottom: 1px solid #b3c1ca;
  float: left;
  width: 30px;
  height: 10px;
  margin-right: 3px;
}

#display-options,
#filter-options,
#temporal-options {
  display: inline-block;
  text-align: center;

  .headline {
    font-size: 11px;
    font-weight: bold;
    color: #666666;
    text-transform: uppercase;

    fa-icon {
      margin-left: 4px;
    }
  }
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f3f3f3;
}

::-webkit-scrollbar-thumb {
  background-color: #b3c1ca;
}

::-webkit-scrollbar-track {
  background-color: #f3f3f3;
}

.category-header:focus {
  text-decoration: none;
}

#data-presenter-header {
  background-color: white;
  position: fixed;
  z-index: 100000;
  width: 100%;
  height: var(--height-header);
}

:target::before {
  content: '';
  display: block;
  height: var(--height-header);
  margin-top: calc(var(--height-header) * -1);
}

#evaluation-content {
  padding-top: var(--height-header);
  max-width: 1440px;
  min-height: 600px;
  position: relative;
  margin: auto;
}

.card-body {
  padding: 0;
}

#data-presenter-header-content {
  max-width: 1390px;
  width: 100%;
  padding: 0 10px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

#griditem1, #griditem2, #griditem3, #griditem4 {
  display: flex;
  align-items: center;
}

.logo-dfb {
  width: 50px;
  height: 50px;
}

.logo-dfl {
  height: 70px;
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: solid 2px #b3c1ca;
  background-color: #b3c1ca;
  color: white !important;
  cursor: pointer;
}

.nav-button:hover {
  color: white;
}

h1 {
  margin-bottom: 0;
}

.category-rating {
  background-color: white;
  padding: 10px;
  width: 20px;
  height: 20px;
  /* Where appropriate, width and height are repeated in the styles-input-property of the fa-icon element so the actual
     icon inside the box is displayed in the right size */
}

.category-rating-positive {
  color: var(--color-rating-positive);
}

.category-rating-neutral {
  color: var(--color-rating-neutral);
  width: 40px;
  height: 40px;
}

.category-rating-negative {
  color: var(--color-rating-negative);
}

#editor-content,
#admin-content {
  max-width: 1370px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 160px;
  position: relative; /* for the absolutely positioned frozen table columns */
  display: flex;
}

#editor-content h4 .info {
  font-weight: normal;
  font-size: 0.8em;
}

.form-wrapper {
  margin-bottom: 60px;
}

.input-error {
  color: #c5242b;
  font-weight: bold;
}

.nav-button.notice {
  background-color: #c4232d;
  border-color: #c5242b;
}

.add-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  border: solid 2px #b3c1ca;
  background-color: #b3c1ca;
  color: white;
  width: 220px;
  height: 30px;
  font-size: 14px;
  margin: 20px auto 0 auto;
}

footer {
  color: white;
  font-size: 12px;
  padding: 30px;
  height: 200px;
  background-color: #b3c1ca;
}

#footer-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  margin: 0 auto 40px auto;
}

#footer-copyright {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

#footer-copyright img {
  display: block;
  margin: 0 auto 15px auto;
}

.binary-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.binary-icon-yes {
  background-color: var(--color-rating-positive);
}

.binary-icon-no {
  background-color: var(--color-rating-negative);
}

.binary-icon-checked {
  background-color: var(--color-rating-positive);
}

.binary-icon-unchecked {
  background-color: var(--color-rating-positive);
  opacity: 0.3;
}

.binary-select input[type=radio] {
  display: none;
}

.save-button:enabled {
  background-color: #c4232d;
  border-color: #c5242b;
}

.personnel-table td {
  padding: 5px;
}

.personnel-table .form-options {
  align-items: flex-start;
}

#editor-nav {
  position: sticky;
  top: 160px;
  width: 290px;
}

#editor-nav nav {
  background-color: #e3e3e3;
  padding: 10px 15px 10px 10px;
  margin-bottom: 10px;
}

#editor-nav header {
  font-size: 16px;
  color: #666666;
}

#editor-nav ul {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#editor-nav li {
  padding-left: 30px;
  margin-top: 5px;
}

#editor-nav a:link,
#editor-nav a:visited {
  color: #666666;
}

#editor-nav a:hover {
  text-decoration: none;
}

#editor-main {
  padding-left: 45px;
  position: relative;
  width: 1080px;
}

.column-options {
  background-color: #b3c1ca;
}

#data-presenter-header-content nav li {
  text-align: center;
}

.criterion-wrapper {
  width: 100%;
  margin: 80px auto 80px auto;
}

hr {
  border: 1px dashed lightgray;
}

.field-size-input {
  width: 50px;
}

.input-divider {
  margin: 0 5px;
}

table.editMode input[type=checkbox] {
  height: initial;
}

.personnel-table label {
  margin-right: 5px;
}

footer a {
  color: inherit;
}

footer a:hover {
  color: inherit;
  text-decoration: none;
}

ngb-modal-window.modal {
  z-index: 100000;
}

.modal-dialog {
  margin-top: 150px;
  max-width: 940px;
}

.modal-content {
  border: none !important;
  border-radius: 0;
  padding-left: 120px;
}

.modal-header {
  font-family: Lato, sans-serif;
  padding-left: 0;
  padding-bottom: 0;
  border-bottom: none !important;
}

.modal-header h4 {
  margin-top: 40px;
  font-size: 34px;
  text-transform: uppercase;
}

.modal-body {
  font-family: Lato, sans-serif;
  font-weight: lighter;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 14px;
  padding: 0 110px 0 0;
}

.modal-footer {
  font-family: Lato, sans-serif;
  padding: 0 0 70px;
  float: left;
  border-top: none !important;
}

.modal-header > .close {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b3c1ca;
  color: #ffffff;
  height: 42px;
  width: 42px;
  font-size: 45px;
  padding: 0 !important;
  border-radius: 0 0 0 5px;
  opacity: 1 !important;
}

.modal-header > .close:hover {
  opacity: 1 !important;
}

.modal-header > .close > span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3px;
}

.modal-footer > button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 15px;
  border-radius: 5px !important;
  border: none !important;
}

.modal-footer > .btn-secondary {
  background-color: #b3c1ca;
}

.modal-footer > .btn-danger {
  background-color: #c4232d;
}

.frozen-column-table-scroller .small-table th:not(:first-child):not(.frozen-column-options),
.frozen-column-table-scroller .small-table td:not(:first-child):not(.frozen-column-options) {
  width: 114px;
}

ngx-spinner .overlay {
  z-index: 9999;
  background: rgb(255 255 255 / 45%);

  .loading-text {
    top: calc(50% + 64px);
  }
}

.spinner-container {
  position: relative;
}

.all-clubs-modal {
  .modal-content {
    padding-left: 0;
    max-height: 500px;
    overflow: auto;
  }
}
